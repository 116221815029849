<template>
  <svg width="23" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.443 16.401a.35.35 0 00-.322.214.345.345 0 00.254.472.35.35 0 00.416-.34.345.345 0 00-.348-.346z"
      fill="currentColor"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path d="M11.443 13.634v-6.92" stroke="currentColor" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" />
    <path
      d="M1.492 13.432a1.666 1.666 0 010-2.362l8.762-8.708a1.68 1.68 0 011.832-.363c.203.084.389.208.544.363l8.763 8.708a1.666 1.666 0 010 2.362L12.63 22.14a1.686 1.686 0 01-2.376 0l-8.762-8.708z"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
